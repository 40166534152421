.body {
    font: white;
    background-color: black;
}

.blinking{
    animation:blinkingText 0.7s infinite;
}
@keyframes blinkingText{
    0%{     color: white;    }
    49%{    color: white; }
    60%{    color: transparent; }
    99%{    color:transparent;  }
    100%{   color: white;    }
}

.figurecard {
    padding: 2rem;
    overflow: hidden;
    overflow-wrap: break-word;
}

.kachel {
    font-family: 'helvetica neue', helvetica, sans-serif;
    font-size: 1.3em;
    text-align: justify;
    text-justify: inter-word;
    height: 50vh;
    overflow-y: scroll;
}
.landingContainer {
    background-color: #282c34;
    color: white;
    font-size: 1rem;
    width: 100vw;
    height: 100vh;

}

.about {
    font-family: 'helvetica neue', helvetica, sans-serif;
    text-align: justify;
    text-justify: inter-word;
}

.news {
    font-family: 'helvetica neue', helvetica, sans-serif;
    text-align: justify;
    text-justify: inter-word;

}

.wo {
    width: 100vw;
    height: 60vh;
    overflow-y: hidden;
    position: fixed;
}



a {
    color: #c3e8fd;
}

.white {
    background-color: white;
    height: 100%;
    z-index: 10000;
    width: 33vw;
}

.scroll {
    overflow-y: scroll;
    height: 100vh;
    width: 100vw;
    background-color: #282c34;
    color: white;
    font-size: 1rem;
}

.chainListBox {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 95vw;
    background-color: #282c34;
    color: white;
    font-size: 1rem;
}

.data {
    height: 25vh;
    overflow-y: scroll;
}

.search {
    font-size: 24px;
    color: white;
    background-color: black;
    flex-direction: row;
    border: solin white;
}


.chainList {
    flex: 1 1 auto;
    overflow: scroll;
    height: 100%;
}

.menu {
    align: center;
    position: fixed;
}

.chainCon {
    width: 20vw;
    overflow-wrap: break-word;
    font-size: 0.8em;
}

.clm {
    border: solid-black;
    background-color: rgb(0, 204, 255);
}

.column {
    width: 50vw;
}

.row {
    border: solid black;
    display: flex;
    flex-direction: row;
    background-color: white;
}

.split {
    height: 100vh;
    display: flex;
    flex-direction: row;
}

.gutter {
    background-color: #eee;
    background-position: 50%;
    cursor: ns-resize;
}

.gutter.gutter-horizontal {
    cursor: ns-resize;
}

.wrap {
    display: flex;
    direction: vertical;
    flex-direction: column;
    height: 90vh;
    width: 100vw;
    gutterAlign: 'center';
}

.viewport {
    flex: 1 1 auto;
    display: inline-block
}

#viewport {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
}

.showfigure {
    flex: 1 1 auto;
    display: inline-block
}

#showfigure {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
}


.wer {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    z-index: 0;
}

.bottomButton {
    margin: 0;
    padding: 0;
    border: 0;
    display: flex;
    align-content: end;
    border: solid red;
}
