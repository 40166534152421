.wer {
  width: 33vw;
  height: 100vh;
  overflow-y: scroll;
  z-index: 0;
}

.kachel {
  font-family: 'helvetica neue', helvetica, sans-serif;
  font-size: 1.3em;
  text-align: justify;
  text-justify: inter-word;
  height: 50vh;
  overflow-y: scroll;
}

.about{
  font-family: 'helvetica neue', helvetica, sans-serif;
  font-size: 1.3em;
  text-align: justify;
  text-justify: inter-word;
}

.wo {
  width: 100vw;
  height: 60vh;
  overflow-y: hidden;
  position: fixed;
}

.white {
  background-color: white;
  height: 100%;
  z-index: 10000;
  width: 33vw;
}

.scroll {
  overflow-y: scroll;
}

.data {
  height: 25vh;
  overflow-y: scroll;
}

.search {
  font-size: 24px;
  color: white;
  background-color: black;
  flex-direction: row;
  border: solin white;
}


