.smallTextBox {
  width: 33vw;
  font-size: 1.5rem;
}

.bigTextBox {
  width: 33vw;
  height: 10vh;
  font-size: 1.5rem;
  resize: vertical;
}
