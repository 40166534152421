.chainList {
  flex: 1 1 auto;
  overflow:scroll;
  height: 100%;
}

.menu {
  align: center;
  position: fixed;
}

.chainCon {
  width: 20vw;
  overflow-wrap: break-word;
  font-size: 0.8em;
}
